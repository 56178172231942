<template>
  <component-wrapper class="square-lovin-fi">
    <grid-container-fi v-if="isMounted">
      <div
        class="square-lovin-fi__container"
        :data-sq-container="fields?.containerId?.value"
        :data-sq-stream="fields?.streamId?.value"
        :data-sq-sub="fields?.subId?.value"
        :data-sq-template="fields?.template?.value"
        :data-sq-lang="fields?.language?.value"
        data-sq-import-on-load="outline"
      />
    </grid-container-fi>
    <loading-indicator
      v-else
      variant="spinnerLocal"
    />
  </component-wrapper>
</template>

<script setup lang="ts">
import { LoadingIndicator } from 'atoms/index';
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper.vue';
import GridContainerFi from 'components/grid-fi/GridContainerFi.vue';
import { inject, onMounted, ref } from 'vue';

defineOptions({
  name: 'SquareLovinFi',
});

const fields = inject('fields');
const isMounted = ref(false);

onMounted(() => {
  if (document && !document.getElementById('square-lovin')) {
    const squareLovinScript = document.createElement('script');
    const head = document.getElementsByTagName('head')[0];
    squareLovinScript.type = 'module';
    squareLovinScript.id = 'square-lovin';
    squareLovinScript.setAttribute(
      'src',
      'https://delivery-assets.squarelovin.com/sdk/squarelovin.min.js',
    );
    head.appendChild(squareLovinScript);
  }

  isMounted.value = true;
});
</script>

<style scoped lang="scss">
@import './square-lovin-fi';
</style>
